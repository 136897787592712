@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?pxxehk');
  src:  url('./icomoon.eot?pxxehk#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?pxxehk') format('truetype'),
    url('./icomoon.woff?pxxehk') format('woff'),
    url('./icomoon.svg?pxxehk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Error .path1:before {
  content: "\e900";
  color: rgb(204, 88, 76);
}
.icon-Error .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Error .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Success .path1:before {
  content: "\e903";
  color: rgb(98, 149, 83);
}
.icon-Success .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Verified .path1:before {
  content: "\e905";
  color: rgb(81, 137, 195);
}
.icon-Verified .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Warning .path1:before {
  content: "\e907";
  color: rgb(241, 176, 84);
}
.icon-Warning .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Warning .path3:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ant-table-filter-dropdown-checkall {
  display: none !important;
}
